<template>
  <div>
    <router-view v-slot="{ Component }">
      <keep-alive>
        <component  :is="Component" v-if="$route.meta.keepAlive" />
      </keep-alive>
    </router-view>
    <router-view v-if="!$route.meta.keepAlive"></router-view>
  </div>
</template>

<script>
export default {
  name: 'App',
  components: {
  },
  //在vue项目中的App.vue中添加公共的方法，这样只需要引入一次，其他页面只需要使用即可
  mounted(){
    // 友盟统计添加
    const script = document.createElement("script");
    script.src = "https://s9.cnzz.com/z_stat.php?id=1280243719&web_id=1280243719";   //该路径为点击统计代码后，页面中第一个 文字统计 script代码
    script.language = "JavaScript";
    document.body.appendChild(script);
  },
  watch: {
    $route() {
      if (window._czc) {
        let location = window.location;
        let contentUrl = location.pathname + location.hash;
        let refererUrl = "/";
        window._czc.push(["_trackPageview", contentUrl, refererUrl]);
      }
    }
  },
  data () {
    return {
      openai:null,
    }
  },
  methods:{
  }
}
</script>

<style>
body{
  margin: 0px !important;
  background: #FFFFFF  !important;
  height: 100%  !important;
}
.layout{
  min-width: 1200px;
}
</style>
